import { Col, Space, Typography } from "antd";
import StyledRow from "./styles";
import * as constants from "./const";

const LandingPage: React.FC = () => {
  return (
    <StyledRow>
      <Col span={24}>
        <Typography.Title level={2}>Description</Typography.Title>
      </Col>

      <Space direction="vertical">
        <Col span={24} style={{ maxWidth: "1200px" }}>
          <Typography.Text className="text">
            The Ciftlik is designed to make farming more accessible and
            efficient for newcomers, seasoned farmers, and companies interested
            in agricultural ventures. This comprehensive farm feasibility tool
            focuses on overcoming one of the main challenges in agriculture:
            helping individuals and corporate entities understand the
            difficulties of starting and managing a farm, particularly from a
            financial perspective.
          </Typography.Text>
        </Col>
        <Typography.Title level={2}>Members</Typography.Title>
        <Typography.Text className="text">
          Mehmet Hasat Serinkan <span className="subtext">21901649</span>{" "}
        </Typography.Text>
        <Typography.Text className="text">
          Mehmet Eren Balasar <span className="subtext">22001954</span>
        </Typography.Text>
        <Typography.Text className="text">
          Zeynep Naz Sevim <span className="subtext">22001795</span>{" "}
        </Typography.Text>
        <Typography.Text className="text">
          Ozan Can Gülbaz <span className="subtext">22002019</span>{" "}
        </Typography.Text>
        <Typography.Text className="text">
          Ahmet Emir Boşnak <span className="subtext">22002398</span>{" "}
        </Typography.Text>
        <Typography.Title level={2}>Supervisor</Typography.Title>
        <Typography.Text className="text">
          İbrahim Körpeoğlu{" "}
          <a
            href={constants.IBRAHIM_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            Link
          </a>
        </Typography.Text>
        <Typography.Title level={2}>Innovation Expert</Typography.Title>
        <Typography.Text className="text">
          Metin Emenullahi{" "}
          <a
            href={constants.METIN_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            Link
          </a>
        </Typography.Text>
        <Space direction="vertical">
          <Typography.Title level={2}>Files</Typography.Title>
          <Typography.Text className="text">
            {" "}
            <a
              href={constants.PROJECT_SPECIFICATION_REPORT}
              target="_blank"
              rel="noopener noreferrer"
            >
              Project Specification Report
            </a>
          </Typography.Text>
          <Typography.Text className="text">
            <a
              href={constants.ANALYSIS_AND_REQUIREMENTS_REPORT}
              target="_blank"
              rel="noopener noreferrer"
            >
              Analysis and Requirements Report
            </a>
          </Typography.Text>
          <Typography.Text className="text">
            <a
              href={constants.DEMO_PRESENTATION}
              target="_blank"
              rel="noopener noreferrer"
            >
              Demo Presentation
            </a>
          </Typography.Text>
        </Space>
        <Typography.Title level={2}>Github Repos</Typography.Title>
        <Typography.Text className="text">
          Please contact{" "}
          <a href={`mailto:${constants.CIFTLIK_MAIL}`}>
            {constants.CIFTLIK_MAIL}
          </a>{" "}
          to access GitHub repositories.
        </Typography.Text>
        <Space direction="vertical">
          <Typography.Title level={2}>Personal Logbooks</Typography.Title>

          <Typography.Text className="text">
            <a
              href={constants.HASAT_PL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Mehmet Hasat Serinkan
            </a>
          </Typography.Text>
          <Typography.Text className="text">
            <a
              href={constants.EREN_PL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Mehmet Eren Balasar
            </a>
          </Typography.Text>
          <Typography.Text className="text">
            <a
              href={constants.ZEYNEP_PL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Zeynep Naz Sevim
            </a>
          </Typography.Text>
          <Typography.Text className="text">
            <a
              href={constants.OZAN_PL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ozan Can Gülbaz
            </a>
          </Typography.Text>
          <Typography.Text className="text">
            <a
              href={constants.EMIR_PL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ahmet Emir Boşnak
            </a>
          </Typography.Text>
        </Space>
      </Space>
    </StyledRow>
  );
};

export default LandingPage;
